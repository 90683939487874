import { useTheme } from "@mui/material";
import { Components } from "../../../components";
import React, { useState } from "react";
import { Typography, Skeleton } from "@mui/material";
import {
  formatAmount,
  getFormattedDate,
  formattedTime,
} from "../../../utils/utilFunctions";
import { useTransactionDetails } from "../../../customHook/useTransactionDetails";
import { useDispatch, useSelector } from "react-redux";
import LocalizedStrings from "react-localization";
import LocalData from "../../../utils/localization";
import moment from "moment";
import {
  Table,
  ChipCell,
  DateTimeCell,
  LinkCell,
} from "@ayoconnect/common-ui-components";

const TransferHistoryTable = ({
  refreshTable,
  list,
  merchantCode,
  merchantName,
}) => {
  const { currentLanguage } = useSelector((state) => state.languageData);
  const strings = new LocalizedStrings(LocalData);
  strings.setLanguage(currentLanguage);

  const theme = useTheme();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [loader, showLoader] = useState(false);
  const [detailsData, setDetailsData] = useState([]);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const { showFrom, showTo, limit } = useSelector(
    (state) => state.paginationData
  );
  // const { merchantCode, isSubmitClicked } = useSelector(state => state.clientAccountData)

  const getDateTime = (mainValue, getValue) => {
    let data = mainValue.toString().split(" ");
    if (getValue === "date") {
      return moment(data[0]).format("DD MMM YYYY");
    }
    if (getValue === "time") {
      return tConvert(data[1]);
    }
  };

  const tConvert = (time) => {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? " AM" : " PM";
      time[0] = +time[0] % 12 || 12;
    }
    return time.join("");
  };

  const getStatusIcon = (status) => {
    switch (status?.toLowerCase()) {
      case "success":
        return "https://storage.googleapis.com/dd-ui-static-dev/images/check-circle2.png";
      case "failed":
        return "https://storage.googleapis.com/dd-ui-static-dev/images/x-circle.png";
      case "processing":
        return "https://storage.googleapis.com/dd-ui-static-dev/images/arrows-right-left.png";
      case "refunded":
        return " https://storage.googleapis.com/dd-ui-static-dev/images/RefundIcon.svg";
      default:
        return "https://storage.googleapis.com/dd-ui-static-dev/images/arrows-right-left.png";
    }
  };

  const getDetailsData = (res) => {
    try {
      let data = res.disbursementDetails;
      const tempData = [
        {
          header: strings.TransferHistory.Transaction_Details,
          status: data.transactionDetails.status,
          partnerRefNo: data.transactionDetails.partnerReferenceNumber,
          customerId: data.customerDetails.customerId,
          merchantName: merchantName,
          merchantCode: merchantCode,
          statusIcon: getStatusIcon(data.transactionDetails.status),
          subData: [
            {
              label1: strings.TransferHistory.Transaction_Creation_Time,
              value1: `${getDateTime(
                data.transactionDetails?.transactionDate,
                "date"
              )}, ${getDateTime(
                data.transactionDetails?.transactionDate,
                "time"
              )}`,
              // label2: strings.TransferHistory.Transaction_Updated_Time,
              // value2: `${getSplitDateTime(data.transactionDetails?.transactionDate, "date")}`
              label2: strings.TransferHistory.Transaction_Id,
              value2: data.transactionDetails?.partnerReferenceNumber,
            },
            {
              // label1: strings.TransferHistory.Transaction_Id,
              // value1: res?.transactionId,
              label1: strings.Amount,
              value1: `Rp ${formatAmount(data?.transactionDetails?.amount)}`,
            },
          ],
        },
        {
          header: strings.TransferHistory.MerchantInfo,
          subData: [
            {
              label1: strings.TransferHistory.MerchantName,
              value1: merchantName,
              label2: strings.TransferHistory.MerchantCode,
              value2: merchantCode,
            },
          ],
        },
        {
          header: strings.TransferHistory.BeneficiaryDetails,
          subData: [
            {
              label1: strings.TransferHistory.BeneId,
              value1: data?.beneficiaryDetails?.beneficiaryId,
              label2: strings.TransferHistory.BeneName,
              value2: data?.beneficiaryDetails?.beneficiaryName || "-",
            },
            {
              label1: strings.TransferHistory.AcNumber,
              value1: data?.beneficiaryDetails?.accountNumber,
              label2: strings.TransferHistory.AccountType,
              value2: data?.beneficiaryDetails?.accountType,
            },
            {
              // label1: strings.TransferHistory.BeneSwiftCode,
              // value1: "Swift Code",
              label1: strings.TransferHistory.BeneBankName,
              value1: data?.beneficiaryDetails?.bankName,
            },
          ],
        },
      ];
      if (data.transactionDetails?.status?.toLowerCase() === "failed") {
        let tempObj = {
          label1: strings.TransferHistory.BankRefNo,
          value1: data.transactionDetails?.bankReferenceNumber,
          label2: strings.TransferHistory.failureReason,
          value2: data.transactionDetails?.failureReason,
          statusColor2: "#CD2029",
        };
        tempData[1].subData.push(tempObj);
      } else {
        let tempObj = {
          label1: strings.TransferHistory.BankRefNo,
          value1: data.transactionDetails?.bankReferenceNumber,
        };
        tempData[1].subData.push(tempObj);
      }
      setDetailsData(tempData);
    }
    catch (err) {
      console.log('err in getDetailsData', { err })
    }
  };

  const { getTransactionDetails } = useTransactionDetails(
    showLoader,
    getDetailsData,
    setErrorText,
    setOpenErrorModal
  );

  const getDetails = (row) => {
    setOpenModal(true);
    getTransactionDetails(
      `/of-admin-dashboard-bff/web/v1/bi-fast-dashboard-bff/disbursement/${row.partnerReferenceNumber}`,
      row,
      merchantCode
    );
  };

  const TableHeaders = [
    {
      field: "transactionDate",
      headerName: strings["DateAndTime"],
      flex: 0.7,
      minWidth: 110,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <DateTimeCell
          date={params?.value?.split(" ")[0]}
          time={params?.value?.split(" ")[1]}
        />
      ),
    },
    {
      field: "partnerReferenceNumber",
      headerName: strings["TransactionId"],
      flex: 2,
      minWidth: 250,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <LinkCell
          id={`link-${params.value}`}
          handleClick={() => {
            getDetails(params.row);
          }}
        >
          {params.value}
        </LinkCell>
      ),
    },
    {
      field: "beneficiaryName",
      headerName: strings["BenificiaryName2"],
      flex: 1,
      minWidth: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "bankName",
      headerName: strings["BankName"],
      flex: 1,
      minWidth: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "amount",
      headerName: strings["Amount"],
      flex: 1,
      minWidth: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => <>Rp{formatAmount(params.value)}</>,
    },
    {
      field: "status",
      headerName: strings["Status"],
      flex: 1,
      minWidth: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => <ChipCell label={params.value} />,
    },
    {
      field: "customerId",
      headerName: strings["CustomerId"],
      flex: 1,
      minWidth: 140,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  return (
    <>
      {!loader && <Table id={"partnerReferenceNumber"} rows={list} columns={TableHeaders} />}

      {loader &&
        Array.from({ length: 16 }).map((e, index) => (
          <Typography variant="h1" key={e + index}>
            <Skeleton />
          </Typography>
        ))}
      <Components.BasePopup setOpen={setOpenModal} show_dialog={openModal}>
        <Components.TransactiondetailsModal
          refreshTable={refreshTable}
          handleUpdateResponse={getDetails}
          displayData={detailsData}
          setClose={setOpenModal}
          loader={loader}
          showLoader={showLoader}
          setErrorText={setErrorText}
          setOpenErrorModal={setOpenErrorModal}
        />
      </Components.BasePopup>
      <Components.BasePopup
        setOpen={setOpenErrorModal}
        show_dialog={openErrorModal}
      >
        <Components.ErrorHandlerModal
          errorText={errorText}
          setClose={setOpenErrorModal}
          setErrorText={setErrorText}
        />
      </Components.BasePopup>
    </>
  );
};

export default TransferHistoryTable;
